/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "ventilatie/ventilatie-2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal2Image: file(
      relativePath: { eq: "ventilatie/ventilatie-8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Airconditioning`,
  textWithLink: () => (
    <>
      <p>
        Bij een airco denken we vaak aan koeling, terwijl het ook zorgt voor
        luchtbeweging, ontvochtiging en verwarming. Kortom, voor een aangenaam
        klimaat in huis. Het is belangrijk dat binnen- en buitentemperatuur niet
        teveel uiteen lopen: maximaal 5°C. Een groter verschil kan leiden tot
        lichamelijke klachten, zoals verkoudheid. Ook de luchtbeweging is
        belangrijk. Een te sterke luchtbeweging geeft een onbehaaglijk gevoel
        van kou en tocht.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/ventilatie'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Airco = ({ data, path, location }) => {
  const seo = {
    title: `Voor al uw airconditioning`,
    description: `E S I geeft u advies op maat voor al uw airconditioning producten.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Het principe van airconditioning',
    inner: `Alle airconditioner's werken volgens hetzelfde principe. De binnenunit zuigt de te koelen binnenlucht aan. Na eerst een stoffilter te zijn gepasseerd, wordt de lucht gekoeld met behulp van een speciaal, milieuvriendelijk koudemiddel dat continu in het airco-systeem wordt rondgepompt. Het neemt de warmte van de te koelen binnenlucht over. Na afkoeling wordt de gekoelde en gefilterde lucht weer de ruimte ingeblazen. Op de juiste temperatuur én in de juiste richting.`,
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.CardHorizontal2Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Vervolg',
    textWithLink: () => (
      <>
        <p>
          Nadat het koudemiddel door opwarming door de binnenlucht gasvormig is
          geworden, wordt het door de buitenunit geleid. Daar wordt het weer
          vloeibaar door afkoeling door de buitenlucht. Vervolgens wordt het
          koudemiddel weer naar binnen gevoerd en begint de airco-cyclus
          opnieuw.
        </p>
        <p>
          Interesse in een aircosysteem voor uw woning of bedrijfsruimte?
          Informeer naar de mogelijkheden.
        </p>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw airconditioning`,
    pitchLine: `Informeer naar de mogelijkheden.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        textBottom={textBottom}
      />
    </>
  );
};

Airco.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Airco;
